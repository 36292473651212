import { Model } from "~/lib/data/mod.js";

/**
 * Global config for the Event
 *
 * @typedef {Object} Config
 * @prop {"venue"|"event"} locationDisplay Whether to show session locations as venue or event name
 */

/**
 * Event Config data
 *
 * @param {Object} data The raw config data
 * @returns {Config} A Config object with default values filled in
 */
export const Config = Model.extend(function Config({
  locationDisplay = "venue",
}) {
  return { locationDisplay };
});
