import { createContext, useContext } from "react";
import { batch, computed, signal, useSignalEffect } from "@preact/signals-react";
import { useEffectOnMounted } from "~/lib/hooks/mod.js";
import { PersistentStorage } from "~/lib/data/mod.storage.js";
import { deserializeBookmarks, serializeBookmarks } from "../Bookmarks/mod.js";

export const createAppState = ({ hasRuntimeJs = false, bookmarks = {} } = {}) => {
  // Default: determine whether JS exists by presence of global `document`. Can be overridden with
  // a boolean value for testing/Storybook.
  const sigHasRuntimeJs = signal(hasRuntimeJs);
  const sigBookmarkingIsEnabled = computed(() => sigHasRuntimeJs.value);

  return {
    hasRuntimeJs: sigHasRuntimeJs,
    isBookmarkingEnabled: sigBookmarkingIsEnabled,
    year: signal(""),
    eventData: {
      bookmarks: signal(bookmarks),
    },
  };
};

const AppState = createContext({});
const appState = createAppState();

export const AppStateProvider = ({ children }) => {
  useEffectOnMounted(() => {
    // Progressive enhancement to signal for JS init
    appState.hasRuntimeJs.value = true;

    // Begin persisted state hydration
    const eventYear = document.querySelector('meta[name="event-year"]')?.content;

    if (!eventYear) {
      console.warn("[WARN]", "Unable to find event year in DOM. Using default app state");
      return;
    }

    const eventData = PersistentStorage.get(`event:${eventYear}`) ?? {};
    batch(() => {
      appState.year.value = eventYear;

      if (eventData.bookmarks) {
        appState.eventData.bookmarks.value = deserializeBookmarks(eventData.bookmarks);
      }
    });

  });

  // Write out `appState.eventData` whenever one of its state values change
  useSignalEffect(() => {
    const { eventData, year } = appState;
    const persistedState = {
      bookmarks: serializeBookmarks(eventData.bookmarks),
    };
    PersistentStorage.set(`event:${year.value}`, persistedState);
  });

  return <AppState.Provider value={appState}>{children}</AppState.Provider>;
};

export const withAppState = (BaseComponent) => {
  const WithInjectedAppState = (props) => {
    const appState = useContext(AppState);

    return <BaseComponent {...props} {...appState} />;
  };

  return WithInjectedAppState;
};
