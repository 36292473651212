import { createContext, useContext } from "react";
import { getComponentName, wrapComponentName } from "~/lib/component/mod.js";

const ConfigContext = createContext({});
ConfigContext.displayName = "ConfigContext";

const { Provider: ConfigProvider } = ConfigContext;

const getConfigProps = (mapConfigToProps, contextValue) => {
  return Object.entries(mapConfigToProps).reduce(
    (props, [propName, contextName]) => {
      const cv = contextValue[contextName];

      if (cv === undefined) {
        console.warn(
          `No config value for ${contextName} found in <ConfigProvider />`
        );
      }

      props[propName] = cv;
      return props;
    },
    {}
  );
};

const withConfig = (mapConfigToProps) => (BaseComponent) => {
  const WithConfig = (ownProps) => {
    const contextValue = useContext(ConfigContext);

    if (!contextValue) {
      throw new Error(
        `${getComponentName(
          BaseComponent
        )} is not wrapped in a <ConfigProvider />`
      );
    }

    const configProps = getConfigProps(mapConfigToProps, contextValue);

    return <BaseComponent {...ownProps} {...configProps} />;
  };

  WithConfig.displayName = wrapComponentName(BaseComponent, WithConfig.name);

  return WithConfig;
};

export { ConfigProvider, withConfig };
