/**
 * Convert `Record<string, true>` in-memory bookmarks to `string[]` for storage
 *
 * @param {Signal<Record<string, true>>} memoryBookmarks
 * @returns {string[]}
 */
export const serializeBookmarks = (memoryBookmarks = {}) => Object.keys(memoryBookmarks.value);

/**
 * Convert `string[]` serialized bookmarks to `Record<string, true>` for in-memory
 *
 * @param {string[]} storageBookmarks
 * @returns {Record<string, true>}
 */
export const deserializeBookmarks = (storageBookmarks = []) =>
  storageBookmarks.reduce((acc, id) => ((acc[id] = true), acc), {});
