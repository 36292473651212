import { getComponentName } from "./getComponentName.js";

/**
 * Wrap a component name with a wrapper component name
 *
 * @param {import('react').ComponentType | string} BaseComponent The component being wrapped
 * @param {import('react').ComponentType | string} WrapperComponent The wrapper component
 * @returns {string} The wrapped component name
 */
export const wrapComponentName = (BaseComponent, WrapperComponent) =>
  `${getComponentName(WrapperComponent)}(${getComponentName(BaseComponent)}`;
