import { Config, ConfigProvider } from "~/features/Config/mod.js";
import { AppStateProvider } from "~/features/AppState/mod.js";

import "~/styles/app.scss";

export default function App({ Component, pageProps }) {
  const { config, ...props } = pageProps;
  return (
    <AppStateProvider>
      <ConfigProvider value={Config(config)}>
        <Component {...props} />
      </ConfigProvider>
    </AppStateProvider>
  );
}
