const MISSING_COMPONENT_NAME = "<MissingComponent>";
/**
 * Get the name of the component
 *
 * @param {import('react').ComponentType | string} Component The component to get the name from or a string value of the name
 * @param {string} fallback The final fallback
 * @returns {string} The component's name if set, fallback if not
 */
export const getComponentName = (Component) => {
  // Handle missing data cases
  if (Component == null || Component === "") {
    return MISSING_COMPONENT_NAME;
  }

  return typeof Component === "string"
    ? Component
    : Component.displayName || Component.name || "Component";
};
